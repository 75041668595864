import Bugsnag from '@bugsnag/js';

import type { SharedApiService } from '@albert/shared/services';
import type { Event, PageTimeEventDto } from '@albert/shared/types';

export const sendEvent = async (
  apiService: SharedApiService,
  eventData: Event,
  sessionId: string | null,
  userId?: string,
): Promise<void> => {
  await apiService.post<void, Event & { sessionId: string | null; userId?: string }>('/events', {
    ...eventData,
    userId,
    sessionId,
  });
};

export const sendPageTimeEvent = async (apiService: SharedApiService, dto: PageTimeEventDto) => {
  await apiService.post('/events/chpv2', dto).catch((error) => {
    Bugsnag.notify(error);
  });
};
