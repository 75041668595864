'use client';

import { useRef } from 'react';
import { uuidv7 } from 'uuidv7';

import { isServer } from '@albert/shared/utils';

interface SessionState {
  id: string;
  expiresAt: number;
}

const createSessionState = (): SessionState => {
  return {
    id: uuidv7(),
    expiresAt: Date.now() + 4 * 60 * 60 * 1000, // 4 hours
  };
};

export const loadSessionState = (): SessionState | null => {
  const serialized = localStorage.getItem('CH_SESSION');
  if (!serialized) {
    return null;
  }

  try {
    const sessionState = JSON.parse(serialized);
    // Don't reuse expired sessions.
    if (!sessionState || sessionState.expiresAt < Date.now()) {
      return null;
    }

    return sessionState;
  } catch {
    return null;
  }
};

const useSessionId = () => {
  const sessionState = useRef<SessionState | null>(null);

  if (isServer) {
    return null;
  }

  if (!sessionState.current) {
    sessionState.current = loadSessionState();
    if (!sessionState.current) {
      sessionState.current = createSessionState();
      localStorage.setItem('CH_SESSION', JSON.stringify(sessionState.current));
    }
  }

  return sessionState.current.id;
};

export default useSessionId;
