import { loadSessionState } from '@shared/hooks/useSessionId';
import { apiService } from 'services';

import { sendEvent as sendSharedEvent, sendPageTimeEvent as sendSharedPageTimeEvent } from '@albert/shared/api';
import type { Event, PageTimeEventDto } from '@albert/shared/types';
import { getDeviceType } from '@albert/shared/utils';

export const sendEvent = async (eventData: Event): Promise<void> => {
  const deviceType = getDeviceType();
  const sessionState = loadSessionState();
  const sessionId = sessionState?.id ?? null;

  await sendSharedEvent(
    apiService,
    {
      ...eventData,
      data: {
        ...eventData.data,
        ...(deviceType && { device_type: deviceType }),
      },
    },
    sessionId,
  );
};

export const sendPageTimeEvent = async (dto: PageTimeEventDto) => {
  await sendSharedPageTimeEvent(apiService, dto);
};
